<template>
  <div :class="position === -1 ? 'top-level-step' : 'step'">
    <div class="top-line"></div>
    <div class="content">
      <div v-if="displayHeader" class="content-header">
        <div class="flex flex-row justify-between">
          <slot name="header"></slot>
          <div>
            <Button
              type="button"
              :aria-label="`collapse-step-${position}`"
              :id="`collapse-step-${position}`"
              @click="displayStep = !displayStep"
              v-tooltip="displayStep ? $t('collapse') : $t('expand')"
              :icon="displayStep ? 'pi pi-chevron-up' : 'pi pi-chevron-down'"
              class="mt-2 ml-4 -mr-4 p-button-text p-button-rounded !shadow-none hover:enabled:bg-spreedly-blue-200 !hover:bg-spreedly-blue-200 focus:bg-spreedly-blue-200"
              rounded
            ></Button>
          </div>
        </div>
      </div>

      <div
        class="transition-all ease-out duration-100"
        :class="{ 'h-0': !displayStep, 'h-fit pb-4': displayStep }"
      >
        <div v-if="displayStep">
          <slot></slot>
        </div>
      </div>
    </div>

    <div class="v-stepper">
      <div class="line"></div>
      <div class="arrow"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Button from "primevue/button";

import { ref } from "vue";

defineProps<{
  position: number;
  displayHeader?: boolean;
}>();

const displayStep = ref(true);
</script>

<style lang="css" scoped>
.step,
.top-level-step {
  display: flex;
  flex-direction: column;
  position: relative;
}

.v-stepper {
  position: relative;
}

.step:not(:last-child) .top-line {
  left: 1rem;
  top: 6rem;
  bottom: 0;
  position: absolute;
  border-left: 3px solid #8f9495;
}

.top-level-step .line,
.step .line {
  left: 1rem;
  top: 0;
  position: absolute;
  border-left: 3px solid #8f9495;
  border-bottom: 3px solid #8f9495;
  width: 1.25rem;
  height: 6rem;
  border-bottom-left-radius: 1rem;
}

.step .line {
  height: 6rem;
}

.top-level-step .arrow,
.step .arrow {
  border: solid #8f9495;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 0.35rem;
  transform: rotate(-45deg);
  position: absolute;
  left: 1.45rem;
  top: 5.45rem;
}

.step:last-child .line {
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  z-index: -1;
}

.step:last-child .arrow {
  border: 3px solid transparent;
  z-index: -1;
}

.step:last-child {
  padding: 0;
}

.content {
  background-color: white;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  border-radius: 0.5rem;
  padding: 1rem 1rem 0 1rem;
  border: solid 1px rgb(229, 231, 235);
  color: #545859;
  flex-direction: row;
  width: fit-content;
  position: relative;
  margin-top: 3rem;
}

.step .content {
  min-width: 744px;
}

.top-level-step .content {
  margin-top: 0;
}

.step .content {
  margin-left: 3rem;
}

.content .content-header {
  padding: 0.5rem 0.75rem 2rem;
}

.step .content .content-header .if-else {
  background: #91bbd0;
  color: #363a3a;
  padding: 0.5rem;
  height: 40px;
  white-space: nowrap;
  margin: 0.5rem 1.5rem 0 -0.5rem;
  border-radius: 7px;
  align-self: center;
  text-align: center;
}

.step:first-child:not(:last-child) .content .content-header .if-else::after {
  content: "If";
}

.step:not(:last-child):not(:first-child)
  .content
  .content-header
  .if-else::after {
  content: "Else If";
}
.step:last-child:not(:first-child) .content .content-header .if-else::after {
  content: "Else";
}
.step:last-child:first-child .content .content-header .if-else {
  display: none;
}

.step:last-child .plus-button {
  display: none;
}

.step:last-child .content .content-header .step-menu-button {
  display: none;
}

.top-level-step .plus-button,
.step .plus-button {
  position: absolute;
  top: 1rem;
  margin: 0;
  left: 3px;
  height: 28px;
  width: 28px;
  z-index: 50;
}
</style>
