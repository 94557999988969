<template>
  <WorkflowVerticalStepper
    :position="step.priority"
    display-header
    :key="step.uuid"
  >
    <template v-slot:header>
      <div class="flex flex-row pt-2 w-full">
        <FloatLabel
          class="-ml-3"
          :pt="{ root: { class: 'w-full' } }"
          :title="v.description.$model"
        >
          <InputText
            :id="`step-description-input-${step.priority}`"
            v-model="v.description.$model"
            :disabled="!hasPermission"
            :maxlength="100"
            @update:model-value="
              steps[step.priority].description = v.description.$model
            "
          />
          <label :for="`step-description-input-${step.priority}`">{{
            $t("stepDescription", { stepIndex: step.priority })
          }}</label>
        </FloatLabel>
      </div>
    </template>
    <div
      v-if="step.conclusions && step.conclusions.length > 0"
      class="bg-[#EEEEEE] rounded-md mt-2"
    >
      <WorkflowConclusion
        v-for="(conclusion, _index) in step.conclusions"
        :key="conclusion.uuid"
        :conclusion="conclusion"
        :conclusion-index="_index"
        :step-index="step.priority"
        @remove-split="removeConclusionSplit"
        @add-split="addSplit(step.priority)"
      ></WorkflowConclusion>
    </div>
  </WorkflowVerticalStepper>
</template>
<script lang="ts" setup>
import WorkflowVerticalStepper from "@/components/WorkflowVerticalStepper.vue";
import WorkflowConclusion from "@/components/WorkflowConclusion.vue";
import InputText from "primevue/inputtext";
import FloatLabel from "primevue/floatlabel";

import { computed, reactive } from "vue";
import { useSettingsStore } from "@/stores/SettingsStore";
import { useVuelidate } from "@vuelidate/core";
import {
  useWorkflow,
  addGatewaySplit,
  removeSplit,
} from "@/composables/useWorkflow";
import type { WorkflowStep } from "@/services/WorkflowService";
import useEventBus from "@/composables/useEventBus";
import { deepCopy } from "@/services/HelperService";

const store = useSettingsStore();
const { action, steps } = useWorkflow();
const { emit } = useEventBus();
const hasPermission = computed(() => {
  return action.value === "update"
    ? store.hasPermission("workflow.update")
    : store.hasPermission("organization.create_workflow");
});

const props = defineProps<{
  step: WorkflowStep;
}>();

const state = reactive({
  description: props.step.description || "",
});
const rules = {
  description: {},
};

const v = useVuelidate(rules, state, { $scope: "workflow-builder" });

const removeConclusionSplit = (conclusionIndex: number) => {
  emit("removeSplit", deepCopy(steps.value));
  removeSplit(conclusionIndex, props.step.priority);
  v.value.$touch();
};
function addSplit(index: number) {
  addGatewaySplit(index);
  v.value.$touch();
}
</script>
